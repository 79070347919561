@charset "utf-8";
&-bg {
	margin-top: 50px;
	&._gray_ {
		background: #F9F9F9;
	}
}
@include media-m-ov {
	
}

@include media-s {
	&-bg {

	}
}


