@charset "utf-8";
&-text {
	display: inline-block;
	border: 1px solid $line-gray;
	color: $text-black;
	padding: 5px 10px;
	width: 100%;
	
	&._w-m_ {
		max-width: 350px;
	}
	&._w-s_ {
		max-width: 100px;
	}
	&._w-a_ {
		width: auto;
	}
}
