@charset "utf-8";
&-pagetop {
	position: fixed;
	right: 55px;
	bottom: 55px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 55px;
		height: 55px;
		background: #7D0101;
		color: $white;
		font-size: $fz-xxxl;
		text-decoration: none;
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}
@include media-s {
	&-pagetop {
		right: 15px;
		bottom: 15px;
	
		&__link {
			width: 40px;
			height: 40px;
		}
	}
}
