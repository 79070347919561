@charset "utf-8";

@for $i from 0 through 100 {
  &-w#{$i}p {
    width: #{$i}% !important;
  }
	@include media-s {
		&-sp-w#{$i}p {
			width: #{$i}% !important;
		}
	}
}

