@charset "utf-8";
&-mb {
	margin-bottom: 15px;
}
@include media-m {

}

@include media-s {

}
