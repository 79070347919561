@charset "utf-8";
&-m {
	margin-bottom: $m-m !important;
}
@include media-s {
	&-m {
		&._sp-mb-0_ {
			margin-bottom: 0 !important;
		}
	}
}
