@charset "utf-8";
&-a-txt-arrow {
	position: relative;
	display: block;
	padding: 0 0 0 1.25em;
	
	.__ico {
		color: $baseColor;
		line-height: 1.2;
		
		&::before {
			display: inline-block;
		}
		&:first-child {
			position: absolute;
			top: 3px;
			left: 5px;
		}
	}
	.__txt {
		color: $text-black;
	}
	&:hover {
		.__elem {
			color: $baseColor;
		}
	}
	
	
	//色オプション 青 -------------------------------
	
}
@include media-s {
	&-a-txt-arrow {
		.__elem {
			.icon-blank {
				font-size: $fz-xs;
			}
		}
	}
}
