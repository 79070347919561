@charset "utf-8";

$num: 5;

// ============================================================
// color
// ============================================================
$baseColor: #D90F24;
$baseColorLight: #FF6A79;
$text-black: #333;

$line-gray: #E5E5E5;

$gray: #F9F9F9;
$red: #dd0000;
$white: #ffffff;
$black: #000000;


// ============================================================
// font size
// ============================================================
$fz-xxxs: 1.0rem;
$fz-xxs: 1.2rem;
$fz-xs: 1.3rem;
$fz-s: 1.4rem;
$fz-m: 1.6rem;
$fz-l: 1.8rem;
$fz-xl: 2.0rem;
$fz-xxl: 2.2rem;
$fz-xxxl: 2.4rem;

// ============================================================
// font-weight
// ============================================================
$font-thin: 100;
$font-light: 200;
$font-demilight: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 900;

// ============================================================
// マージンサイズ
// ============================================================
$m-xxxs: 10px;
$m-xxs: 15px;
$m-xs: 20px;
$m-s: 25px;
$m-m: 30px;
$m-l: 60px;
$m-xl: 80px;
$m-xxl: 100px;
$m-xxxl: 150px;


// ============================================================
// grid
// ============================================================
$maxcol: 12;
$max-width: 100%;
$margin: 30px; //PC用 カラム間余白
$margin-sp: 10px; //SP用 カラム間余白
