@charset "utf-8";
&-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	min-width: 150px;
	height: 50px;
	margin: 10px 5px;
	padding: 0 30px;
	background: $baseColor;
	border: 1px solid transparent;
	color: $white;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include transition;
	@include radius(3);
	
	&:not(._disabled_) {
		cursor: pointer;
	}
	&:not(._disabled_):hover {
		background: $white;
		border-color: $baseColor;
		color: $baseColor;
	}
	&._disabled_ {
		opacity: .5;
	}
	
	//サイズオプション Lサイズ-------------------------------
	&._w-l_ {
		min-width: 300px;
		height: 60px;
		font-size: $fz-m;
	}
	//サイズオプション Sサイズ-------------------------------
	&._w-s_ {
		min-width: inherit;
		height: 40px;
	}
	//カラーオプション アウトライン-------------------------------
	&._outline_ {
		background: $white;
		border: 1px solid $baseColor;
		color: $baseColor;
		&:not(._disabled_):hover {
			background: $baseColor;
			color: $white;
		}
	}
	//カラーオプション 白------------------------------
	&._white_ {
		background: $white;
		color: $black;
		&:hover {
			color: $baseColor;
		}
	}
	//カラーオプション 黒------------------------------
	&._black_ {
		background: $black;
		color: $white;
		border: 1px solid $black;
		&:hover {
			border: 1px solid $black;
			color: $white;
			color: $black;
		}
	}
	//カラーオプション グレー-------------------------------
	&._gray_ {
		background: #E1E1E1;
		color: $black;
		border: 1px solid #E1E1E1;
		&:not(._disabled_):hover {
			color: $black;
			background: $white;
			border: 1px solid #E1E1E1;
		}
	}
}
@include media-s {
	&-btn {
		height: 40px;
		margin: 5px;
		
		//サイズオプション Lサイズ-------------------------------
		&._w-l_ {
			width: 100%;
			min-width: 0;
			height: 60px;
		}
		
		//サイズオプション Sサイズ-------------------------------
		&._w-s_ {
			min-width: 0;
			min-height: 35px;
			padding: 0 20px;
			font-size: $fz-xxs;
		}
	}
}