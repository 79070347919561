@charset "utf-8";
&-radio {
	.__elem {
		display: none;
		&:checked + .__txt {
			&::after {
				opacity: 1;
			}
		}
	}
	
	.__txt {
		position: relative;
		padding: 0 0 0 20px;
		cursor: pointer;
		&::before {
			left: 0;
			border: 2px solid #d9d9d9;
			content: '';
			@include centering-elements(false, true);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			content: '';
			opacity: 0;
			@include centering-elements(false, true);
			@include circle(8);
		}
	}
}
