@charset "utf-8";
@include media-m-ov {
	&-gnav {
		flex: 1 1 auto;
		&__list {
			display: flex;
		}
		&__item {
			&:hover {
				.c-gnav__link::before {
					width: 100%;
				}
			}
		}
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 90px;
			padding: 0 10px;
			color: $black;
			font-size: $fz-l;
			font-weight: $font-medium;
			text-decoration: none;

			&::before {
				position: absolute;
				left: 50%;
				bottom: 0;
				z-index: 5;
				content: '';
				width: 0;
				height: 3px;
				background: $baseColor;
				backface-visibility: hidden;
				transform: translateX(-50%);
				@include transition;
			}
			.__ico {
				display: none;
			}
		}
	}
}
@include media-m {
	&-gnav {
		&__link {
			padding: 0 5px;
		}
	}
}
@include media-s {
	&-gnav {
		margin: 0 0 30px;
		&__item {
			border-bottom: 1px solid #3A3A3A;
			font-size: $fz-m;
			
			&:first-child {
				border-top: 1px solid #3A3A3A;
			}
		}
		&__link {
			color: $white;
			text-decoration: none;
			position: relative;
			width: 100%;
			height: 100%;
			padding: 15px;
			display: block;
			.__ico {
				@include centering-elements(false, true);
				right: 15px;
				&.icon-plus {
					font-size: $fz-s;
				}
			}
		}
		._gnav-show_ & {
			z-index: 1;
			opacity: 1;
			pointer-events: auto;
		}
	}
}
