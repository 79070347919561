@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?aicl7');
  src:  url('/common/icon/fonts/icomoon.eot?aicl7#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?aicl7') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?aicl7') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?aicl7') format('woff'),
    url('/common/icon/fonts/icomoon.svg?aicl7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	.__hidden {
		@include hidden;
	}
}

.icon-filter_none:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e900";
}
.icon-contact:before {
  content: "\e901";
}
.icon-english:before {
  content: "\e902";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-phone:before {
  content: "\f095";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
