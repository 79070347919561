@charset "utf-8";

// ============================================================
//  breakpoint 
// ============================================================
@mixin media-m-ov {
	@media screen and (min-width: 767.5px) {
		@content;
	}
}

@mixin media-m {
	@media screen and (min-width: 767.5px) and (max-width: 1024.499px) {
		@content;
	}
}

@mixin media-s {
	@media screen and (max-width: 767.499px) {
		@content;
	}
}


// ============================================================
//  font 
// ============================================================
@mixin font-normal {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 400;
}

@mixin font-medium {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 500;
}

@mixin font-bold {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 700;
}

@mixin roboto {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}
@mixin roboto-light {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	letter-spacing: 0.05em;
}
@mixin roboto-bold {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	letter-spacing: 0.05em;
}


// ============================================================
//  grid 
// ============================================================
@mixin column($col){
	width: calc(#{$max-width} / #{$maxcol} * #{$col} - #{$margin});

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		width: calc(#{$max-width} / #{$maxcol} * #{$col} - #{$margin} - 1px);
  }
  margin-left: $margin / 2;
  margin-right: $margin / 2;
  margin-bottom: $margin;
}
@mixin sp-column($col){
	width: calc(#{$max-width} / #{$maxcol} * #{$col} - #{$margin-sp});
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		width: calc(#{$max-width} / #{$maxcol} * #{$col} - #{$margin-sp} - 1px);
  }
  margin-left: $margin-sp / 2;
  margin-right: $margin-sp / 2;
  margin-bottom: $margin-sp;
}



// ============================================================
//  other 
// ============================================================
@mixin hidden {
	font-size: 1px;
	position: absolute;
	top: -999999px;
	left:-999999px;
	clip: rect(1px, 1px, 1px, 1px);
	width: 1px;
	height: 1px;
	display: block;
	opacity: 0;
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition($duration: .4s) {
	transition: $duration all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin placeholder($color) {
	&::placeholder {
		color: $color;
		font-weight: $font-demilight;
	}
	&:-ms-input-placeholder {
		color: $color;
		font-weight: $font-demilight;
	}
	&::-ms-input-placeholder {
		color: $color;
		font-weight: $font-demilight;
	}
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin iehack() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}