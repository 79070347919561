@charset "utf-8";
&-required {
	background: $baseColor;
	color: $white;
	font-size: $fz-xxs;
	padding: 0 5px;
	min-width: 40px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	display: inline-block;
	@include font-normal;
	margin: 0 0 0 10px;
}
