@charset "utf-8";
&-footer {
	background: $black;
	color: #DCDCDC;
	&__inner {
		max-width: 1140px;
		padding: 45px 20px 45px;
		margin: 0 auto;
		display: flex;
	}
	&Logo {
		flex: 0 0 90px;
		margin: 0 30px 0 0;
		&._en_ {
			flex: 0 0 70px;
			margin: 0;
		}
	}
	&Address {
		font-size: 1.5rem;
		flex: 1 1 auto;
		margin: 0 30px 0;
		a[href^="tel:"] {
			color: #DCDCDC;
		}
	}
	&Navi {
		flex: 1 1 40%;
		&._en_ {
			flex: 1 1 35%;
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
		}
		&__item {
			margin: 0 35px 0 0;
			&::before {
				content: "\f105";
				font-family: 'icomoon';
				color: #9A918F;
				margin: 0 10px 0 0;
			}
		}
		&__link {
			color: #DCDCDC;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&Copyright {
		padding: 20px 0;
		color: #9A918F;
		border-top: 1px solid #292929;
		font-size: $fz-xxs;
		text-align: center;
	}
}
@include media-s {
	&-footer {
		&__inner {
			max-width: 100%;
			padding: 45px 15px;
			display: block;
		}
		&Logo {
			margin: 0 0 20px;
			text-align: center;
			&._en_ {
				margin: 0 0 20px;
			}
		}
		&Address {
			margin: 0 0 20px;
			text-align: center;
			&__item {
				margin: 0 0 10px;
				line-height: 1.4;
				&.m-indent {
					display: block;
					.__item {
						display: inherit;
					}
				}
			}
		}
		&Navi {
			&__list {
				justify-content: center;
			}
		}
	}
}
