@charset "utf-8";
&-indent {
	display: table;
	
	.__item {
		display: table-cell;
		white-space: nowrap;
	}
	&._space_ .__item {
		padding-right: 1em;
	}
}
@include media-s {
	&-indent {
	}
}