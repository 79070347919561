@charset "utf-8";
&-box {
	padding: 25px;
	background: $white;

	&._gray_ {
		background: #f9f9f9;
	}
	&._pink_ {
		background:#FFEBED;
	}
	&._line-gray_ {
		border: 1px solid $line-gray;
	}
}
@include media-s {
	&-box {
		padding: 15px;
	}
}