@charset "utf-8";
&-list-circle {
	&__item {
		position: relative;
		margin-bottom: 5px;
		padding-left: 15px;
		
		&::before {
			position: absolute;
			top: 0.7em;
			left: 0;
			display: block;
			content: '';
			background: $baseColor;
			@include circle(6);
		}
	}
	&._black_ &__item {
		&::before {
			background: $text-black;
		}
	}
}
@include media-s {
	&-list-circle {
	}
}
