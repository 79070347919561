@charset "utf-8";

@include media-m-ov {
	&-s {
		display: none !important;
	}
}

@include media-s {
	&-s {
	}
}
