@charset "utf-8";
&-check {
	.__elem {
		display: none;
		&:checked + .__txt {
			&::after {
				opacity: 1;
			}
		}
	}
	.__txt {
		position: relative;
		padding: 0 0 0 20px;
		cursor: pointer;
		&::before {
			left: 0;
			width: 14px;
			height: 14px;
			border: 2px solid #d9d9d9;
			content: '';
			@include centering-elements(false, true);
		}
		&::after {
			left: 1px;
			color: $baseColor;
			font-family: 'icomoon';
			font-size: 1.6rem;
			content: '\f00c';
			font-weight: 600;
			opacity: 0;
			@include centering-elements(false, true);
		}
	}
}
