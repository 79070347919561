@charset "utf-8";
&-news-list {
	margin: 0 0 40px;
	border-top: 1px solid $line-gray;
	
	&__item {
		display: flex;
		align-items: center;
		padding: 25px 0;
		border-bottom: 1px solid $line-gray;
	}
	&__date {
		flex: 0 0 auto;
		margin: 0 20px 0 0;
	}
	&__label {
		flex: 0 0 auto;
		margin: 0 25px 0 0;
		padding: 0 10px;
		background: $black;
		color: $white;
		font-size: $fz-s;
		min-width: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
	}
	&__link {
		.__ico {
			color: #980909;
		}
	}
}
@include media-s {
	&-news-list {
		&__item {
			flex-wrap: wrap;
			padding: 15px 0;
		}
		&__date {
			margin: 0 10px 0 0;
			font-size: $fz-s;
		}
		&__label {
			line-height: 1.2;
		}
		&__link {
			width: 100%;
			padding: 0 0 0 15px;
			margin: 10px 0 0;
			position: relative;
			&::before {
				font-family: 'icomoon';
				content: "\f105";
				position: absolute;
				top: 3px;
				left: 0;
				color: #980909;
			}
		}
	}
}
