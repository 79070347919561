@charset "utf-8";

@include media-m-ov {
	&-m {
	}
}

@include media-s {
	&-m {
		display: none !important;
	}
}
