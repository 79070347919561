@charset "utf-8";
@include media-m-ov {
	&-row {
		&__inner {
			max-width: 1140px;
			padding: 50px 20px 50px;
			margin: 0 auto;
			&._narrow_ {
				max-width: 1040px;
			}
		}
	}
}

@include media-m {
	&-row {
	}
}
@include media-s {
	&-row {
		&__inner {
			max-width: 100%;
			padding: 30px 15px 30px;
			&._footer_ {
				padding: 30px 15px 40px;
			}
		}
	}
}


