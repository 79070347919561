@charset "utf-8";
&-h5 {
	margin: 0 0 20px;
	padding: 3px 0 3px 15px;
	border-left: 3px solid $baseColor;
	font-size: $fz-l;
	line-height: 1.4;
	@include font-bold;
}

@include media-s {
	&-h5 {
		margin: 0 0 15px;
		padding: 2px 0 2px 10px;
		font-size: $fz-m;
	}
}
