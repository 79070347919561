@charset "utf-8";
&-greeting {
	background: url(/common/img/index/bg_79_01.jpg) top right no-repeat;
	padding: 50px 0 0 0;
	
	&__prof {
		display: flex;
		.__ttl {
			margin: 0 50px 0 0;
		}
	}
}
@include media-m-ov {
	&-greeting {
		
	}
}

@include media-m {
	&-greeting {

	}
}
@include media-s {
	&-greeting {
		padding: 20px 0 0 0;
		background-size: 50% auto;
		background-position: top 40px right -10px;
		&__prof {
			display: block;
			.__ttl {
				margin: 0;
			}
		}
	}
}
