@charset "utf-8";
&-h1 {
	margin: 0 0 50px;
	color: $baseColorLight;
	font-size: 1.5rem;
	line-height: 1.3;
	font-weight: $font-medium;
	text-align: center;
	.__en {
		display: block;
		color: $baseColor;
		font-size: 5.0rem;
		@include roboto-bold;
		position: relative;
		padding: 0 15px 0 0;
		&::after {
			margin: 0 0 0 10px;
			position: absolute;
			bottom: 10px;
			content: "";
			width: 14px;
			height: 1px;
			border-bottom: 1px solid $baseColorLight;
		}
	}
	&._greeting_ {
		display: flex;
		align-items: flex-end;
		margin: 0 0 30px;
		.__en {
			line-height: 1.0;
			margin: 10px 20px 0 0;
		}
	}
	&._prof_ {
		text-align: left;
		.__en {
			font-size: 3.6rem;
		}
	}
	&._contact_ {
		font-size: 1.7rem;
	}
	&._underlayer_ {
		padding: 40px 0;
		border-bottom: 1px solid $baseColorLight;
		margin: 0;
	}
}

@include media-s {
	&-h1 {
		margin: 0 0 30px;
		.__en {
			font-size: 4.0rem;
		}
		&._greeting_ {
			display: block;
			text-align: left;
			margin: 0 0 20px;
			.__en {
				margin: 0 0 10px 0;
			}
		}
		&._prof_ {
			margin: 0 0 20px;
		}
	}
}
