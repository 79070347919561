@charset "utf-8";
&-fc {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: center !important;
	& > * {
		flex: 0 0 auto !important;
	}
}
@include media-s {
	&-c {
	}
}
