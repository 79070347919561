@charset "utf-8";
&-l {
	text-align: left !important;
}
@include media-s {
	&-l {
		&._sp-al-l_ {
			text-align: left !important;
		}
		&._sp-al-c_ {
			text-align: center !important;
		}
		&._sp-al-r_ {
			text-align: right !important;
		}
	}
}
