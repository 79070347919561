@charset "utf-8";
@include media-m-ov {
	&-scroll {
		padding-top: 90px;
		margin-top: -90px;
	}
}

@include media-s {
	&-scroll {
		padding-top: 50px;
		margin-top: -50px;		
	}
}


