@charset "utf-8";
&-tel {
	font-size: $fz-xl;
	font-weight: $font-medium;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.3;
	margin-right: 30px;
	.__num {
		font-size: 3.2rem;
	}
}
@include media-m {

}

@include media-s {
	&-tel {
		margin: 0 auto;
	}
}
