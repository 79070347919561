@charset "utf-8";
&-wrap {
	padding: 90px 0 0;
	color: $text-black;
	font-size: $fz-m;
	line-height: 1.8;
	overflow-x: hidden;
}
@include media-s {
	&-wrap {
		padding: 50px 0 0;
		font-size: $fz-s;
	}
}
