@charset "utf-8";
&-contact {
	color: $white;
	text-align: center;
	font-size: $fz-l;
	position: relative;
	margin-top: -200px;
	padding: 0 20px;
	margin-bottom: 40px;
	&__inner {
		background-image: url(/common/img/index/bg_79_02.png);
		background-repeat: no-repeat;
		background-position: left -50px bottom -30px;
		background-color: $baseColor;
		position: relative;
		&::before {
			@include centering-elements(true, false);
			top: 0;
			content: "";
			border-top: 4px solid $white;
			width: 200px;
			height: 4px;
		}
	}
	.__catch {
		margin: 0 0 30px;
		font-size: 2.8rem;
	}
	&__link {
		margin: 0 0 30px;
		.__item {
			display: flex;
			align-items: center;
			.e-tel {
				.__num {
					color: $white;
				}
			}
		}
	}
}
@include media-m-ov {
	&-contact {
		&__inner {
			&.l-row__inner {
				max-width: 1100px;
			}
		}
	}
}

@include media-m {
	&-contact {

	}
}
@include media-s {
	&-contact {
		margin-top: -200px;
		margin-bottom: 30px;
		.__catch {
			margin: 0 0 20px;
			font-size: $fz-xl;
			line-height: 1.4;
		}
	}
}
