@charset "utf-8";
&-fl {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: flex-start !important;
}
@include media-s {
	&-l {
	}
}
