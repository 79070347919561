@charset "utf-8";
@include media-m-ov {
	&-table-slide {
		&__coution {
			display: none;
		}
	}
}
@include media-s {
	&-table-slide {
		&__coution {
			font-size: $fz-xxs;
		}
		&__body {
			margin: $m-xxxs 0;
			overflow: auto;
			overflow-scrolling: touch;
			
			table {
				white-space: nowrap;
			}
		}
	}
}
