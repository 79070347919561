@charset "utf-8";
&-h3 {
	font-size: $fz-l;
	font-weight: $font-medium;
	margin: 0 0 15px;
}

@include media-s {
	&-h3 {
	}
}
