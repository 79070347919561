@charset "utf-8";
&-snav {
	&Head {
		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 80px;
			background: $baseColor;
			color: $white;
			font-size: $fz-l;
			text-align: center;
			text-decoration: none;
		}
	}
	&List {
		&__item {
			border: 1px solid $line-gray;
			border-top: none;
		}
		&__link {
			display: flex;
			align-items: center;
			padding: 15px;
			text-decoration: none;
			
			.__txt {
				flex: 1 1 auto;
			}
			.__ico {
				flex: 0 0 auto;
				margin: 0 0 0 10px;
				font-size: $fz-xxs;
			}
			&:hover {
				opacity: 1;
				background: rgba($baseColor, .05);
			}
		}
	}	
}
@include media-s {
	&-snav {
	}
}
