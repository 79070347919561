@charset "utf-8";
body {
	@include font-normal;
}
a {
	color: $baseColor;
	text-decoration: underline;
	
	.icon-filter_none::before,
	.icon-file-pdf-o::before {
		display: inline-block;
		
		@include iehack {
			height: 1em;
			overflow-y: hidden;
		}
	}
	&._td-n_ {
		text-decoration: none;
	}
}
sup {
	vertical-align: super;
}
input,
textarea {
	appearance: none;
	max-width: 100%;
}
input::placeholder {
	opacity: .5;
}
button,
input[type="button"],
input[type="submit"] {
	appearance: none;
}
.icon-filter_none,
.icon-file-pdf-o {
	margin: 0 5px;
	color: $baseColor;
}

@include media-m-ov {
	html {
		overflow-y: scroll;
	}
	a {
		&:hover {
			text-decoration: none;
		}
		&._opacity_ {
			&:hover {
				@include transition;
				opacity: 0.7;
			}
		}
		&[href^="tel:"] {
			color: $text-black;
			text-decoration: none;
			pointer-events: none;
		}
	}
}

@include media-m {
	html {
		font-size: calc(100vw / 102.4);
	}
}

@include media-s {
	body {
		&._gnav-show_ {
			overflow-y: hidden;
		}
	}
	input,
	select,
	textarea {
		font-size: $fz-m !important;
	}	
}