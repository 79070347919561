@charset "utf-8";
&-contact-form {
	&Tel-box {
		width: 80%;
		margin: 0 auto 40px;
		padding: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: $font-medium;
		&__item {
			width: 50%;
			text-align: center;
		}
		&__txt {
			font-size: $fz-xl;
			font-weight: $font-medium;
			.__ico {
				color: $baseColor;
				margin: 0 5px 0 0;
			}
		}
		
	}
	&__table {
		padding-top: 40px;
	}
	&__inner {
		margin-top: -80px;
		padding-top: 80px;
	}
}
@include media-s {
	&-contact-form {
		&Tel-box {
			width: 100%;
			margin: 0 auto 30px;
			padding: 20px;
			display: block;
			&__item {
				width: 100%;
			}
		}
		&__table {
			padding-top: 20px;
		}

		&__inner {
			margin-top: -50px;
			padding-top: 50px;
		}
	}
}
