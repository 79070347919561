@charset "utf-8";
&-h6 {
	margin: 0 0 15px;
	color: $baseColor;
	font-size: $fz-m;
	@include font-bold;
}

@include media-s {
	&-h6 {
		margin: 0 0 15px;
		font-size: $fz-s;
	}
}
