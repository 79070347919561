@charset "utf-8";
&-h2 {
	margin: 0 0 15px;
	font-size: 2.8rem;
	line-height: 1.4;
	font-weight: $font-medium;
	border-left: 4px solid $baseColorLight;
	padding: 0 0 0 10px;
	position: relative;
	&::before {
		content: "";
		width: 4px;
		height: 75%;
		border-left: 4px solid $baseColor;
		top: 0;
		left: -4px;
		position: absolute;
	}
}

@include media-s {
	&-h2 {
		margin: 0 0 15px;
		font-size: $fz-xxl;
	}
}
