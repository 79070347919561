@charset "utf-8";
&-c {
	text-align: center !important;
	margin-right: 0 !important;
	margin-left: 0 !important;
}
@include media-s {
	&-c {
		&._sp-al-l_ {
			text-align: left !important;
		}
		&._sp-al-c_ {
			text-align: center !important;
		}
		&._sp-al-r_ {
			text-align: right !important;
		}
	}
}
