@charset "utf-8";
&-float {
	&::after {
		display: block;
		content: '';
		clear: both;
	}
	&__item {
		max-width: 50%;
		margin-bottom: 20px;

		img {
			max-width: 100%;
		}
	}
	&._left_ &__item {
		float: left;
		margin-right: 20px;
	}
	&._right_ &__item {
		float: right;
		margin-left: 20px;
	}
}
@include media-s {
	&-float {
		&__item {
			margin-bottom: 15px;
		}
		&._left_ &__item {
			margin-right: 15px;
		}
		&._right_ &__item {
			margin-left: 15px;
		}
		&._sp-col1_ &__item {
			float: none;
			max-width: none;
			width: 100%;
			margin: 0 0 15px;
			text-align: center;
		}
	}
}
