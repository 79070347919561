@charset "utf-8";
&-iframe {
	position: relative;
	height: 0;
	padding-bottom: 69.81%;
	overflow: hidden;

	.__elem {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
@include media-s {
	&-iframe {
	}
}
