@charset "utf-8";
&-fr {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: flex-end !important;
}
@include media-s {
	&-r {
	}
}
