@charset "utf-8";
&-about {
	position: relative;
	overflow: hidden;
	padding-bottom: 240px;
	&::before {
		content: "";
		z-index: -1;
		background: url(/common/img/index/bg_about.png) bottom left no-repeat;
		background-size: cover;
		position: absolute;
		top: 15%;
		width: 100%;
		height: 100%;
	}
	&__ttl {
		font-size: 3.0rem;
		font-weight: $font-medium;
		text-align: center;
		line-height: 1.3;
		margin: 0 0 30px;
		&::after {
			content: attr(data-text);
			display: block;
			color: $baseColorLight;
			@include roboto-bold;
			font-size: 1.7rem;
		}
		.__em01 {
			font-size: 4.0rem;
			@include roboto-bold;
		}
		.__em02 {
			font-size: 6.0rem;
			@include roboto-bold;
			color: $baseColor;
		}
	}
	&__item {
		background: $white;
		padding: 40px;
		box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
		position: relative;
		height: 100%;
		.__number {
			position: absolute;
			top: 0;
			left: 30px;
			color: #FFEBED;
			font-size: 8.0rem;
			@include roboto-bold;
			line-height: 0.8;
		}
		.__ico {
			display: block;
			margin: 0 auto 30px;
		}
		.__catch {
			font-size: $fz-xl;
			font-weight: $font-medium;
			margin: 0 0 15px;
			text-align: center;
		}
	}
}
@include media-m-ov {
	&-about {
		
	}
}

@include media-m {
	&-about {

	}
}
@include media-s {
	&-about {
		&::before {
			top: 7%;
		}
		&__ttl {
			font-size: 2.6rem;
			.__em01 {
				font-size: 3.6rem;
			}
			.__em02 {
				font-size: 5.0rem;
			}
		}
		&__item {
			padding: 30px 20px;
			.__number {
				left: 20px;
				font-size: 8.0rem;
			}
			.__catch {
				font-size: $fz-l;
				text-align: left;
			}
		}
	}
}
