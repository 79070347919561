@charset "utf-8";
&-l {
	margin-bottom: $m-l !important;
}
@include media-s {
	&-l {
		margin-bottom: $m-l / 2 !important;
		&._sp-mb-l_ {
			margin-bottom: $m-l !important;
		}
	}
}
