@charset "utf-8";
@include media-m-ov {
	&-header {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		background: $white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 0 15px;
		&Logo {
			flex: 0 0 200px;
			margin: 0 15px 0 0;

			&__link {
				display: block;

				.__elem {
					width: 100%;
				}
			}
			&._en_ {
				flex: 0 0 160px;
			}
		}
		&Menu {
			display: none;
		}
		&Nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex: 1 1 auto;
			&__lang {
				margin: 0 15px;
				.__ico {
					margin: 0 7px;
					&::before {
						display: inline-block;

						@include iehack {
							height: 1em;
							overflow-y: hidden;
						}
					}
				}
				&:hover {
					text-decoration: underline;
				}
			}
			&__link {
				text-decoration: none;
				color: $black;
			}
			&__contact {
				height: 90px;
				flex: 0 0 200px;
				background: $black;
				color: $white;
				display: flex;
				align-items: center;
				text-decoration: none;
				justify-content: center;
				@include transition;
				border: 1px solid $black;
				font-size: $fz-l;
				font-weight: $font-medium;
				.__ico {
					margin: 0 10px;
				}
				&:hover {
					color: $black;
					background: $white;
				}
				&._en_ {
					flex: 0 0 180px;
				}
			}
			&__close {
				display: none;
			}
			&__contact-sp {
				display: none;
			}
		}
	}
}
@include media-m {
	&-header {
		padding: 0 0 0 10px;
		&Logo {
			flex: 0 0 150px;
			margin: 0 5px 0 0;
			&._en_ {
				flex: 0 0 130px;
			}
		}
		&Nav {
			&__lang {
				margin: 0 10px 0 0;
			}
			&__contact {
				flex: 0 0 150px;
				&._en_ {
					flex: 0 0 140px;
				}
			}
		}
	}
}
@include media-s {
	&-header {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		background: $white;
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0;
		&Logo {
			flex: 0 0 140px;
			line-height: 0;
			margin: 0 0 0 10px;
			.__elem {
				width: 100%;
			}
		}
		&Menu {
			position: relative;
			width: 50px;
			height: 50px;
			z-index: 10;
			margin-left: auto;
			cursor: pointer;
			@include transition;
			background: $black;
			
			&__item {
				display: inline-block;
				@include centering-elements(true, false);
				width: 60%;
				height: 2px;
				background: $white;
				@include radius(2);
				@include transition;
				
				&:nth-of-type(1) { top: 12px; }
				&:nth-of-type(2) { top: 24px; }
				&:nth-of-type(3) { top: 36px; }
			}
			&._active_ &__item {
				background: $white;
				
				&:nth-of-type(1) { transform: translateY(12px) translateX(-13px) rotate(-318deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-11px) translateX(-13px) rotate(318deg); } 
			}
		}
		&Nav {
			position: fixed;
			left: 0;
			top: 50px;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: #292929;
			opacity: 0;
			pointer-events: none;
			@include transition;
			padding: 0 0 50px;
			overflow-y: scroll;
			._gnav-show_ & {
				z-index: 1;
				opacity: 1;
				pointer-events: auto;
			}
			&__lang {
				margin: 0 auto 30px;
				text-align: center;
				font-size: $fz-l;
				.__ico {
					font-size: $fz-xl;
					margin: 0 7px;
					&::before {
						display: inline-block;

						@include iehack {
							height: 1em;
							overflow-y: hidden;
						}
					}
				}
				&:hover {
					text-decoration: underline;
				}
			}
			&__link {
				text-decoration: none;
				color: $white;
			}
			&__contact-sp {
				text-align: center;
				color: $white;
				margin: 0 0 30px;
				.__ttl {
					color: $baseColorLight;
					font-size: $fz-m;
					font-weight: $font-medium;
					margin: 0 0 15px;
				}
				.e-tel {
					margin-right: 0;
					margin-bottom: 10px;
					.__num {
						color: $white;
						font-size: 2.8rem;
					}
				}
				._w-l_ {
					width: 90%;
				}
			}
			&__contact {
				display: none;
			}
			&__close {
				text-align: center;
				font-size: 3.6rem;
				margin: 0 0 40px;
				a {
					color: $white;
					text-decoration: none;
				}
			}
		}
	}
}
