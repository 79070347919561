@charset "utf-8";
&-visual {
	position: relative;
	&__inner {
		max-width: 1140px;
		margin: 0 auto;
		@include centering-elements(true, false);
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		top: 0;
	}
	&__txt {
		margin: 0 0 0 70px;
		font-size: 2.6rem;
		font-weight: $font-medium;
		color: $white;
		line-height: 2.5;
		.__elem {
			background: linear-gradient(to right, #e30d23 27%, #720712 100%);
			padding: 5px 30px;
		}
	}
}

@include media-m {
	&-visual {

	}
}
@include media-s {
	&-visual {
		&__txt {
			position: absolute;
			bottom: 50px;
			margin: 0 0 0 20px;
			font-size: $fz-m;
			.__elem {
				padding: 5px 10px;
			}
		}
	}
}
